<form autocomplete="off">
  <div class="form-group hidden">
    <input name="datepicker" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'"
      (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="dayTemplate" outsideDays="hidden"
      [startDate]="fromDate!">
    <ng-template #dayTemplate let-date let-focused="focused">
      <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
      </span>
    </ng-template>
  </div>
  <div class="d-flex align-items-start">
    <button class="btn btn-outline-secondary btn-calendar" (click)="datepicker.toggle()" type="button">
      <mat-icon>calendar_today</mat-icon>
    </button>
    <mat-form-field class="date-selection-input">
      <input matInput [value]="dateSelection" (click)="datepicker.toggle()" [placeholder]="(dateSelection == '' ? 'RANGE_DATE_PICKER.PLACE_HOLDER' : '') | translate">
      <mat-icon *ngIf="dateSelection" matSuffix aria-label="Clear"
      (click)="removeDateFilter()">close</mat-icon>
    </mat-form-field>
  </div>
</form>
