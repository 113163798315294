import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbDatepicker, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'range-datepicker',
    templateUrl: './range-datepicker.component.html',
    styleUrls: ['./range-datepicker.component.scss'],
    standalone: true,
    imports: [NgIf, MatFormFieldModule, MatInputModule, MatIconModule, MatDatepickerModule, NgbDatepickerModule, TranslateModule]
})
export class RangeDatepickerComponent implements OnInit {

  hoveredDate: NgbDate | null = null
  fromDate: NgbDate | null = null
  toDate: NgbDate | null = null
  dateSelection: string = ""

  @Input() initalRange!: { start: Date | null, end: Date | null }
  @Output() setRange: EventEmitter<{ start: Date, end: Date }> = new EventEmitter();
  @Output() removeRange = new EventEmitter();
  @ViewChild('datepicker', { static: true }) ngbDatepicker: any

  constructor(
    public formatter: NgbDateParserFormatter
  ) { }

  ngOnInit(): void {
    this.initRange()
  }

  initRange() {
    if (this.initalRange && this.initalRange.start !== null && this.initalRange.end !== null) {
      const startDate = NgbDate.from(this.toNgbDate(this.initalRange.start))
      const endDate = NgbDate.from(this.toNgbDate(this.initalRange.end))
      if (startDate !== null) {
        this.onDateSelection(startDate)
      }
      if (endDate !== null) {
        this.onDateSelection(endDate)
      }
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      let start = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
      let end = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day + 1, 0, 0, 0, -1)
      this.ngbDatepicker.close()
      this.setRange.emit({ start: start, end: end })
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.dateSelection = this.formatter.format(this.fromDate) + "/" + this.formatter.format(this.toDate)
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  removeDateFilter() {
    this.dateSelection = ""
    this.fromDate = null
    this.toDate = null
    this.removeRange.emit()
  }

  toNgbDate(date: Date): NgbDateStruct | null {
    return date ? {
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate()
    } : null;
  }
}
